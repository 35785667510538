import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import axios from 'axios';

class Search extends Component {

    state = {
        search: "",
        needsSending: [],
        onProof: [],
        needsEdits: [],
        passed: [],
        lastYear: []
    };

    componentDidMount = () => {
        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?status=0&SORT=name,ASC')
        .then((res) => {
            this.setState({
                needsSending: res.data
            })
        });
        
        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?status=1&SORT=name,ASC')
        .then((res) => {
            this.setState({
                onProof: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?status=2&SORT=name,ASC')
        .then((res) => {
            this.setState({
                needsEdits: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?status=3&SORT=name,ASC')
        .then((res) => {
            this.setState({
                passed: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?status=4&SORT=name,ASC')
        .then((res) => {
            this.setState({
                lastYear: res.data
            })
        });
    };

    search = (e) => {

        this.setState({
            search: e.target.value
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?name=*'+e.target.value+'*&status=0&SORT=name,ASC')
        .then((res) => {
            this.setState({
                needsSending: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?name=*'+e.target.value+'*&status=1&SORT=name,ASC')
        .then((res) => {
            this.setState({
                onProof: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?name=*'+e.target.value+'*&status=2&SORT=name,ASC')
        .then((res) => {
            this.setState({
                needsEdits: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?name=*'+e.target.value+'*&status=3&SORT=name,ASC')
        .then((res) => {
            this.setState({
                passed: res.data
            })
        });

        axios.get('https://proofing-api.bbg.support/db.php/profiles/search?name=*'+e.target.value+'*&status=4&SORT=name,ASC')
        .then((res) => {
            this.setState({
                lastYear: res.data
            })
        });
    };

    render() {
        return (
            <>
                <form>
                    <input type="text" className="search" placeholder="Type to search..." value={this.state.search} onChange={(e) => { this.search(e) }} />
                </form>

                <div className="list">

                    { this.state.needsSending.length > 0 ?
                        this.state.needsSending.map((business, businessIdx) => {
                            return (
                                <NavLink to={"/view/"+business.id}>
                                    <p>{business.name}</p>
                                    <p>Needs Sending</p>
                                </NavLink>
                            )
                        })
                    : null }

                    { this.state.onProof.length > 0 ?
                        this.state.onProof.map((business, businessIdx) => {
                            return (
                                <NavLink to={"/view/"+business.id}>
                                    <p>{business.name}</p>
                                    <p>On Proof</p>
                                </NavLink>
                            )
                        })
                    : null }

                    { this.state.needsEdits.length > 0 ?
                        this.state.needsEdits.map((business, businessIdx) => {
                            return (
                                <NavLink to={"/view/"+business.id}>
                                    <p>{business.name}</p>
                                    <p>Needs Edits</p>
                                </NavLink>
                            )
                        })
                    : null }

                    { this.state.passed.length > 0 ?
                        this.state.passed.map((business, businessIdx) => {
                            return (
                                <NavLink to={"/view/"+business.id}>
                                    <p>{business.name}</p>
                                    <p>Passed</p>
                                </NavLink>
                            )
                        })
                    : null }

                    { this.state.lastYear.length > 0 ?
                        this.state.lastYear.map((business, businessIdx) => {
                            return (
                                <NavLink to={"/view/"+business.id}>
                                    <p>{business.name}</p>
                                    <p>2020</p>
                                </NavLink>
                            )
                        })
                        : null }

                </div>
            </>
        );
    }
}

export default Search;