import React, { Component } from 'react';
import history from './history';

import axios from 'axios';
import cloneDeep from 'clone-deep';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class View extends Component {

    state = {
        editorState: EditorState.createEmpty(),
        creating: false,
        name: "",
        status: 0,
        type: 3,
        yearsQualified: 1,
        established: 2019,
        employees: 1,
        recommendations: "",
        service: "",
        strengths: "",
        businessHours: "",
        contact: "",
        tel: "",
        email: "",
        website: "",
        contactOverride: "",
        caption: "",
        captionColour: 0,
        file: "",
        categories: ["", "", "", ""]
    };

    change = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            contactOverride: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
    };

    updateCategories = (idx, e) => {
        let categories = this.state.categories;
        categories[idx] = e.target.value;
        this.setState({
            categories: categories
        })
    };

    addCategory = () => {
        let categories = this.state.categories;
        categories[categories.length + 1] = "";
        this.setState({
            categories: categories
        })
    };

    save = (e) => {

        this.setState({
            creating: true
        });

        let formData = new FormData();
        formData.append("files", this.state.file);

        axios.post("https://proofing-api.bbg.support/upload.php", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {

            let data = cloneDeep(this.state);
            data["categories"] = JSON.stringify(data["categories"]);
            delete(data["file"]);
            delete(data["creating"]);
            delete(data["editorState"]);
            data["image"] = res.data.writtenPaths[0];

            axios.post("https://proofing-api.bbg.support/db.php/profiles", data)
            .then((res) => {

                this.setState({
                    creating: false
                });

                history.push('/');
                alert("'"+res.data[0].name + "' was successfully created.")
            })
        })
        .catch((err) => {
            alert('There was an issue uploading that image, please try again.');
            this.setState({
                creating: false
            })
        })
    };

    render() {
        return (
            <>
                <div className="options">

                    <h3>Create New Profile</h3>

                    <p>Business Name</p>
                    <input type="text" value={this.state.name} id="name" onChange={(e) => { this.change(e) }} />

                    <p>Profile Type:</p>
                    <select value={this.state.type} id="type" onChange={(e) => { this.change(e) }}>
                        <option value="3">Half Page</option>
                        <option value="4">B</option>
                        <option value="5">C</option>
                        <option value="6">D</option>
                        <option value="7">E</option>
                        <option value="8">F</option>
                        <option value="9">AIL</option>
                    </select>

                    <p>Years Qualified</p>
                    <input type="text" value={this.state.yearsQualified} id="yearsQualified" onChange={(e) => { this.change(e) }} />

                    <p>Established</p>
                    <input type="text" value={this.state.established} id="established" onChange={(e) => { this.change(e) }} />

                    <p>Employees</p>
                    <input type="text" value={this.state.employees} id="employees" onChange={(e) => { this.change(e) }} />

                    <p>Recommendations</p>
                    <textarea value={this.state.recommendations} id="recommendations" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Service</p>
                    <textarea value={this.state.service} id="service" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Strengths</p>
                    <textarea value={this.state.strengths} id="strengths" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Business Hours</p>
                    <input type="text" value={this.state.businessHours} id="businessHours" onChange={(e) => { this.change(e) }} />

                    <p>Contact</p>
                    <input type="text" value={this.state.contact} id="contact" onChange={(e) => { this.change(e) }} />

                    <p>Tel</p>
                    <input type="text" value={this.state.tel} id="tel" onChange={(e) => { this.change(e) }} />

                    <p>Email</p>
                    <input type="text" value={this.state.email} id="email" onChange={(e) => { this.change(e) }} />

                    <p>Website</p>
                    <input type="text" value={this.state.website} id="website" onChange={(e) => { this.change(e) }} />

                    <p>Contact Override</p>
                    <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options:['inline'],
                            inline: {
                                options: ['bold']
                            }
                        }}
                    />

                    <p>Image</p>
                    <input type="file" onChange={(e) => { this.setState({ file: e.target.files[0] }) }} />

                    <p>Caption</p>
                    <input type="text" value={this.state.caption} id="caption" onChange={(e) => { this.change(e) }} />

                    <p>Caption Colour</p>
                    <select value={this.state.captionColour} id="captionColour" onChange={(e) => { this.change(e) }}>
                        <option value="0">White</option>
                        <option value="1">Black</option>
                    </select>

                    <p>Categories</p>
                    {this.state.categories.map((cat, idx) => {
                        return (
                            <input type="text" className="category" value={this.state.categories[idx]} onChange={this.updateCategories.bind(this, idx)}/>
                        )
                    })}
                    <p onClick={this.addCategory.bind(this)}>+ Add Category</p>

                    <button onClick={this.save} className={this.state.creating ? "processing" : null}>Create Profile</button>
                
                </div>
            </>
        )
    }
}

export default View;