import React, { Component } from 'react';
import { Router, Route, NavLink } from 'react-router-dom';
import history from './history.js';

import Search from './Search.js';
import View from './View.js';
import Create from './Create.js';

class App extends Component {

    state = {
        loggedIn: false
    };

    componentDidMount(){
        let password = localStorage.getItem("poofing-auth");
        let stamp = localStorage.getItem("poofing-auth-stamp");
        let limit = new Date(new Date(stamp).getTime() + 60000);

        if(stamp !== null){
            if(new Date() > limit){
                localStorage.removeItem("poofing-auth");
                localStorage.removeItem("poofing-auth-stamp");
                window.location.href = "/";
            }
        }

        if(password === "1234"){
            this.setState({
                loggedIn: true
            })
        }
    }

    login = (e) => {
        e.preventDefault();
        localStorage.setItem("poofing-auth", this.state.password);
        localStorage.setItem("poofing-auth-stamp", new Date());
        window.location.href = "/";
    };

    logout = (e) => {
        e.preventDefault();
        localStorage.removeItem("poofing-auth");
        window.location.href = "/";
    };

    render() {
        return (
            this.state.loggedIn === true ?
                <>
                    <Router history={history}>
                        <nav>
                            <NavLink to="/">Profile List / Search</NavLink>
                            <NavLink to="/create">+ Create Profile</NavLink>
                            <span onClick={this.logout}>Log Out</span>
                        </nav>
                        <Route exact path="/" component={Search} />
                        <Route exact path="/view/:id" component={View} />
                        <Route exact path="/create" component={Create} />
                        
                    </Router>
                </>
            :
                <>
                    <input type="text" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value })} } placeholder="Password..." />
                    <input type="submit" onClick={this.login} value="Login" />
                </>
        )
    }
}

export default App;