import React, { Component } from 'react';
import history from './history';

import axios from 'axios';
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import slugify from 'slugify';
import Cropper from 'react-easy-crop';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import pdfMake from 'pdfmake';

import cloneDeep from 'clone-deep';

import { Prompt } from 'react-router';

class View extends Component {

    state = {
        editorState: EditorState.createEmpty(),
        processing: {
            save: false,
            export: false,
            delete: false
        },
        name: "",
        type: 1,
        yearsQualified: 1,
        established: 2019,
        employees: 1,
        recommendations: "",
        service: "",
        strengths: "",
        businessHours: "",
        contact: "",
        tel: "",
        email: "",
        website: "",
        contactOverride: null,
        caption: "",
        captionColour: 1,
        file: "",
        crop: { x: 0, y: 0 },
        zoom: 1,
        prompt: true,
        categories: [""]
    };

    output = React.createRef();

    componentDidMount(){
        axios.get('https://proofing-api.bbg.support/db.php/profiles/'+this.props.match.params.id)
        .then((res) => {
            let data = res.data[0];
            if(data["crop"] !== null){
                data["crop"] = JSON.parse(data.crop)
            }
            data["categories"] = JSON.parse(data["categories"]);

            this.setState(data, () => {
                if(data.contactOverride){
                    const html = data.contactOverride;
                    const contentBlock = htmlToDraft(html);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);
                        this.setState({
                            editorState
                        })
                    }
                }
            });
            console.log(data)
        })
    }

    onCropChange = (crop) => {
        if(crop.x != Infinity){
            this.setState({ crop })
        }
    };

    onZoomChange = (zoom) => {
        this.setState({ zoom })
    };

    resetImage = () => {
        this.setState({
            crop: {x: 0, y: 0},
            zoom: 1
        })
    };

    change = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    };

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
            contactOverride: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        });
    };

    replaceImage = (e) => {

        alert("Replacing image, profile will autosave and then refresh. Please wait.");

        let formData = new FormData();
        formData.append("files", e.target.files[0]);

        axios.post("https://proofing-api.bbg.support/upload.php", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            this.setState({
                image: res.data.writtenPaths[0]
            }, () => {
                this.save();
            })
        })
    } ;

    updateCategories = (idx, e) => {
        let categories = this.state.categories;
        categories[idx] = e.target.value;
        this.setState({
            categories: categories
        })
    };

    addCategory = () => {
        let categories = this.state.categories;
        categories[categories.length + 1] = "";
        this.setState({
            categories: categories
        })
    };

    export = () => {

        let processing = {
            save: false,
            export: true,
            delete: false,
        };

        this.setState({
            processing: processing
        });

        let fileName = slugify(this.state.name, {
            replacement: '-',
            remove: /[*+~.,()/'"!:@]/g,
            lower: true
        });

        fileName = fileName + ".pdf";

        processing = {
            save: false,
            export: false,
            delete: false
        };

        html2canvas(this.output.current, {
            useCORS: true,
            logging: true,
            scale: 4,
            dpi: 300,
            x: 25,
            y: 161,
            allowTaint : true
        }).then((canvas) => {
            const data = canvas.toDataURL();
            const docDefinition = {
                content: [{
                    image: data,
                    width: 1000,
                }],
                pageSize: {
                    width: 1100,
                    height: 'auto'
                }
            };
            pdfMake.createPdf(docDefinition).download(fileName);
            this.setState({
                processing: processing
            })
        }).catch((err) => {
            console.log(err)
        });
    };

    save = () => {

        let processing = {
            save: true,
            export: false,
            delete: false
        };

        this.setState({
            processing: processing
        });

        let data = cloneDeep(this.state);
        data["crop"] = JSON.stringify(this.state.crop);
        data["categories"] = JSON.stringify(data["categories"]);
        delete(data["file"]);
        delete(data["processing"]);
        delete(data["prompt"]);
        delete(data["editorState"]);

        processing = {
            save: false,
            export: false,
            delete: false
        };

        axios.put("https://proofing-api.bbg.support/db.php/profiles/"+this.props.match.params.id, data)
        .then((res) => {
            this.setState({
                processing: processing,
                prompt: false
            }, () => {
                history.push('/');
            })
        })
    };

    delete = () => {

        let processing = {
            save: false,
            export: false,
            delete: true
        };

        this.setState({
            processing: processing
        });

        processing = {
            save: false,
            export: false,
            delete: false
        };

        axios.delete("https://proofing-api.bbg.support/db.php/profiles/"+this.props.match.params.id)
        .then((res) => {
            this.setState({
                processing: processing
            }, () => {
                history.push('/');
            })
        })
    };

    render() {

        console.log(this.state)

        let image = this.state.image;

        let crop = this.state.crop;
        if(crop === null){
            crop = { x: 0, y: 0 };
        }

        let zoom = this.state.zoom;
        if(zoom === null){
            zoom = 1;
        }

        return (
            <>
                <Prompt
                    when={this.state.prompt}
                    message='You may have unsaved changes, are you sure you want to leave?'
                />
                <div id="output" ref={this.output}>
                    <br/><br/><br/>
                    <div className={"profile profile-"+this.state.type}>
                        <div className="main">

                        { this.state.type <= 7 ?
                            <div className="heading">
                                <p>{this.state.name}</p>
                                {this.state.yearsQualified.length > 0 ?
                                    <div>
                                        <p>Years Qualified</p>
                                        <p><strong>{this.state.yearsQualified}</strong></p>
                                    </div>
                                : null}
                            </div>
                        :
                            <div className="heading">
                                {this.state.yearsQualified.length > 0 ?
                                    <p>{this.state.yearsQualified}</p>
                                : null}
                                <p>{this.state.name} -</p>
                                {this.state.service.length > 0 ?
                                    <p>{this.state.service}</p>
                                : null}
                            </div>
                        }

                        {this.state.recommendations.length > 0 ?
                            <div className="recommendations">
                                <img src={require('./images/research.jpg')} />
                                <p><pre>{this.state.recommendations}</pre></p>
                            </div>
                        : null}
                        
                        { this.state.type <= 5 ?
                            <div className="para">
                                <p>
                                    <b>{this.state.established.length > 0 ?
                                        "Established: "
                                    : null}</b>
                                    {this.state.established.length > 0 ?
                                        this.state.established
                                    : null}
                                    <b>{this.state.employees.length > 0 ?
                                        " Employees: "
                                    : null}</b>
                                    {this.state.employees.length > 0 ?
                                        this.state.employees
                                    : null}
                                </p>
                            </div>
                        : null }

                        { this.state.type <= 7 ?
                            this.state.service.length > 0 ?
                                <div className="para">
                                    <p><b>Service:</b> <pre>{this.state.service}</pre></p>
                                </div>
                            : null
                        : null }

                        { this.state.type <= 4 ?
                            this.state.strengths.length > 0 ?
                                <div className="para">
                                    <p><b>Strengths:</b> <pre>{this.state.strengths}</pre></p>
                                </div>
                            : null
                        : null}

                        { this.state.type <= 5 ?
                            this.state.businessHours.length > 0 ?
                                <div className="para">
                                    <p><b>Business Hours:</b> {this.state.businessHours}</p>
                                </div>
                            : null
                        : null}

                        <div className="bottom">
                            { this.state.type <= 6 ?
                                <hr />
                            : null}

                            {!this.state.contactOverride ?
                                <div className="para contact">
                                    <p>
                                        <span>
                                            <b>{this.state.contact.length > 0 ?
                                                "Contact: "
                                                : null}</b>
                                            {this.state.contact.length > 0 ?
                                                this.state.contact
                                                : null}
                                        </span>
                                            <span>
                                            <b>{this.state.tel.length > 0 ?
                                                "Tel: "
                                                : null}</b>
                                                {this.state.tel.length > 0 ?
                                                    this.state.tel
                                                    : null}
                                        </span>
                                            <span>
                                            <b>{this.state.email.length > 0 ?
                                                "Email: "
                                                : null}</b>
                                                {this.state.email.length > 0 ?
                                                    this.state.email
                                                    : null}
                                        </span>
                                            <span>
                                            <b>{this.state.website.length > 0 ?
                                                this.state.website
                                                : null}</b>
                                        </span>
                                    </p>
                                </div>
                            :
                                <div className="para contact" dangerouslySetInnerHTML={{__html: this.state.contactOverride}} />
                            }
                        </div>
                    </div>
                        { this.state.type <= 8 ?
                            <div className="image">
                                { this.state.type <= 6 ?
                                    this.state.caption.length > 0 ? 
                                        <p className={this.state.captionColour == 0 ? "captionWhite" : "captionBlack"}>{this.state.caption}</p>
                                    : null
                                : null }
                                <Cropper
                                    image={image}
                                    crop={crop}
                                    zoom={zoom}
                                    onCropChange={this.onCropChange}
                                    onZoomChange={this.onZoomChange}
                                    restrictPosition={false}
                                    showGrid={false}
                                    minZoom={0}
                                    maxZoom={100}
                                />
                            </div>
                        : null }
                    </div>

                    <div className="categories">
                        <p><strong>Categories:</strong></p>
                        <p>
                            {this.state.categories.map((category, idx) => {
                                if(idx === 0){
                                    return (<span>{category} (Main Category),</span>)
                                } else {
                                    return (<span> {category},</span>)
                                }
                            })}
                        </p>
                    </div>

                    <br/><br/><br/><br/>

                </div>

                    

                <div className="options">

                    <h3>Edit Profile</h3>

                    <button onClick={this.resetImage}>Reset Image Size &amp; Position</button>

                    <p>Business Name</p>
                    <input type="text" value={this.state.name} id="name" onChange={(e) => { this.change(e) }} />

                    <p>Status:</p>
                    <select value={this.state.status} id="status" onChange={(e) => { this.change(e) }}>
                        <option value="0">Needs Sending</option>
                        <option value="1">On Proof</option>
                        <option value="2">Needs Edits</option>
                        <option value="3">Passed</option>
                        <option value="4">2020</option>
                    </select>

                    <p>Profile Type</p>
                    <select value={this.state.type} id="type" onChange={(e) => { this.change(e) }}>
                        <option value="3">Half Page</option>
                        <option value="4">B</option>
                        <option value="5">C</option>
                        <option value="6">D</option>
                        <option value="7">E</option>
                        <option value="8">F</option>
                        <option value="9">AIL</option>
                    </select>

                    <p>Years Qualified</p>
                    <input type="text" value={this.state.yearsQualified} id="yearsQualified" onChange={(e) => { this.change(e) }} />

                    <p>Established</p>
                    <input type="text" value={this.state.established} id="established" onChange={(e) => { this.change(e) }} />

                    <p>Employees</p>
                    <input type="text" value={this.state.employees} id="employees" onChange={(e) => { this.change(e) }} />

                    <p>Recommendations</p>
                    <textarea value={this.state.recommendations} id="recommendations" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Service</p>
                    <textarea value={this.state.service} id="service" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Strengths</p>
                    <textarea value={this.state.strengths} id="strengths" onChange={(e) => { this.change(e) }}></textarea>

                    <p>Business Hours</p>
                    <input type="text" value={this.state.businessHours} id="businessHours" onChange={(e) => { this.change(e) }} />

                    <p>Contact</p>
                    <input type="text" value={this.state.contact} id="contact" onChange={(e) => { this.change(e) }} />

                    <p>Tel</p>
                    <input type="text" value={this.state.tel} id="tel" onChange={(e) => { this.change(e) }} />

                    <p>Email</p>
                    <input type="text" value={this.state.email} id="email" onChange={(e) => { this.change(e) }} />

                    <p>Website</p>
                    <input type="text" value={this.state.website} id="website" onChange={(e) => { this.change(e) }} />

                    <p>Contact Override</p>
                    <Editor
                        editorState={this.state.editorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options:['inline'],
                            inline: {
                                options: ['bold']
                            }
                        }}
                    />

                    <p>Replace Image</p>
                    <input type="file" onChange={(e) => { this.replaceImage(e) }} />

                    <p>Caption</p>
                    <input type="text" value={this.state.caption} id="caption" onChange={(e) => { this.change(e) }} />

                    <p>Caption Colour</p>
                    <select value={this.state.captionColour} id="captionColour" onChange={(e) => { this.change(e) }}>
                        <option value="0">White</option>
                        <option value="1">Black</option>
                    </select>

                    <p>Categories</p>
                    {this.state.categories.map((cat, idx) => {
                        return (
                            <input type="text" className="category" value={this.state.categories[idx]} onChange={this.updateCategories.bind(this, idx)}/>
                        )
                    })}
                    <p onClick={this.addCategory.bind(this)}>+ Add Category</p>

                    <button onClick={this.save} className={this.state.processing.save ? "processing" : null}>Save Profile</button>
                    <button onClick={this.export} className={this.state.processing.export ? "processing" : null}>Export Profile</button>
                    <button onClick={this.delete} className={this.state.processing.delete ? "processing" : null}>Delete Profile</button>
                
                </div>
                <div id='tt'></div>
            </>
        )
    }
}

export default View;